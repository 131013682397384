<template>
  <layout
    :style="`background: ${$theme.background};`"
    title="Relatório Vendas"
    :options="options"
  >
    <v-flex :style="`background: ${$theme.background};`" class="pa-3 pl-0" xs12>
      <div class="expande-horizontal pr-4">
        <Roller
          v-if="$vuetify.breakpoint.smAndUp"
          class="roller"
          :text="$helper.formataSaldo(get_relatorios.balanco)"
        ></Roller>
        <span v-else class="fonte roller-mobile">
          {{ $helper.formataSaldo(get_relatorios.balanco) }}
        </span>
      </div>
    </v-flex>
    <div class="pl-3 expande-horizontal ">
      <Filtros :fire="listar_relatorios_venda" />
    </div>
    <div
      v-if="card"
      class="expande-horizontal wrap"
      style="background: #e2e2e2;"
    >
      <VisualizacaoEmCard
        v-show="get_relatorios.docs.length > 0"
        v-for="payload in get_relatorios.docs"
        :key="payload.id"
        :payload="payload"
      />
    </div>

    <div v-else class="expande-horizontal pl-3 wrap">
      <VisualizacaoEmLista v-show="get_relatorios.docs.length > 0" />
    </div>

    <ListagemVazia v-show="get_relatorios.docs.length === 0" />

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="proposta"
      :manual-pagination="true"
      pdf-format="a4"
      :pdf-quality="10"
      pdf-orientation="portrait"
      @progress="() => {}"
      @hasStartedGeneration="() => {}"
      @hasDownloaded="hasGenerated($event)"
      ref="html2Pdf"
    >
      <div slot="pdf-content">
        <section class="pdf-item">
          <div class="expande-horizontal centraliza coluna">
            <h1 class="font-weight-bold">{{ $route.name }}</h1>
          </div>
          <v-flex
            :style="`background: ${$theme.background};`"
            class="pa-3"
            xs12
          >
            <v-card
              :color="$theme.background"
              class="expande-horizontal column centraliza"
              width="200"
              height="100"
            >
              <h3 class="green--text">R$ {{ get_relatorios.balanco }}</h3>
              <span>TOTAL</span>
            </v-card>
          </v-flex>
          <div v-if="card" class="expande-horizontal centraliza pa-6 wrap">
            <VisualizacaoEmCard
              v-show="get_relatorios.docs.length > 0"
              v-for="payload in get_relatorios.data"
              :key="payload.id"
              :payload="payload"
            />
          </div>

          <div v-else class="expande-horizontal centraliza pa-6 wrap">
            <VisualizacaoEmLista v-show="get_relatorios.docs.length > 0" />
          </div>
        </section>
      </div>
    </vue-html2pdf>
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import Paginacao from "../components/Paginacao.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import { mapActions, mapGetters } from "vuex";
import Roller from "vue-roller";

export default {
  data() {
    return {
      card: false,
      options: [
        // {
        //   icon: "mdi-plus",
        //   nome: "Novo transacaos",
        //   action: () => this.abre_modal_view_transacaos()
        // },
        // {
        //   icon: "mdi-update",
        //   nome: "Atualizar",
        //   action: () => this.listar_transacoes()
        // },
        // {
        //   icon: "mdi-printer",
        //   nome: "Imprimir",
        //   action: () => this.imprimir()
        // }
        // {
        //     icon: 'mdi-ballot-outline',
        //     nome: 'Visualizar em Lista',
        //     action: () => this.card = false
        // },
        // {
        //     icon: 'mdi-post',
        //     nome: 'Visualizar em Card',
        //     action: () => this.card = true
        // }
      ]
    };
  },
  components: {
    Filtros,
    VisualizacaoEmCard,
    VisualizacaoEmLista,
    Paginacao,
    ListagemVazia,
    ModalView,
    Roller
  },
  computed: {
    ...mapGetters([
      "get_relatorios",
      "get_relatorios_filtros",
      "get_clientes_filtros",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "abre_modal_view_transacaos",
      "listar_transacoes",
      "listar_relatorios_venda",
      "listar_whatsapps",
      "listar_clientes"
    ]),
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    }
  },
  created() {
    // this.listar_transacoes();
    this.listar_relatorios_venda();
    this.get_clientes_filtros.all = true;
    this.listar_clientes();
    // this.listar_whatsapps();
  },
  beforeDestroy() {
    this.get_clientes_filtros.all = false;
  }
};
</script>

<style>
.roller {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  text-align: center;
  color: #010f50;
}
.roller-mobile {
  font-weight: bold;
  font-size: 19pt;
  color: #010f50;
}
</style>
